<template>
  <div class="layout-config-body">

      <!--s: header-->
      <div class="config-header">
          <div class="header-left">
              <a href="javascript:" class="back" @click="onBack"><Icon type="ios-arrow-back" /></a>
          </div>
          <div class="config-center"><Input v-model="configParams.configName" @on-focus="configNameVisible = true" @on-keyup="onValidateName" maxlength="20" :show-word-limit="(flowReturnObj.status ===0 && configNameVisible) || (JSON.stringify(flowReturnObj)=='{}' && configNameVisible)?true:false" :disabled="flowReturnObj.status === 0 || JSON.stringify(flowReturnObj)=='{}'?false:true" :border="configNameVisible" @on-blur="configNameVisible = false" :class="configNameVisible?'center':'left'"  placeholder="未命名" /></div>
          <div class="header-right">
              <a href="javascript:" class="icon" :class="!saveStatus?'not-allowed':''" @click="onSave" v-if="flowReturnObj.status === 0 || JSON.stringify(flowReturnObj)=='{}'">
                  <Tooltip v-if="saveStatus" content="手动保存" placement="bottom">
                      <Icon custom="image hold" />
                  </Tooltip>
                  <Icon v-if="!saveStatus" custom="image hold-greey" />
              </a>
              <a href="javascript:" class="icon" @click="onRelease(1)" v-if="flowReturnObj.status === 0 && JSON.stringify(flowReturnObj)!='{}'">
                  <Tooltip content="发布" placement="bottom">
                      <Icon custom="image upload" />
                  </Tooltip>
              </a>
              <a href="javascript:" class="icon" @click="onRelease(0)" v-if="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1 && flowReturnObj.useNum <1">
                  <Tooltip content="撤回" placement="bottom">
                      <Icon custom="image down-load" />
                  </Tooltip>
              </a>
<!--              <a href="javascript:" class="icon">-->
<!--                  <Tooltip content="测试二维码" placement="bottom">-->
<!--                      <Icon custom="image code" />-->
<!--                  </Tooltip>-->
<!--              </a>-->
<!--              <a href="javascript:" class="icon" @click="taskModalVisible = true" v-if="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1">-->
<!--                  <Tooltip content="创建任务" placement="bottom">-->
<!--                      <Icon custom="image found" />-->
<!--                  </Tooltip>-->
<!--              </a>-->
          </div>
      </div>
      <!--e: header-->

      <!--s: body-->
      <div class="config-body">

          <div class="canvas-tips" v-if="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1">
              <div class="box"><Icon custom="info-icon"></Icon> 当前工作流已发布，仅查看不可编辑</div>
          </div>
          <div class="canvas-screen-wrap">

              <!--s: config Menu-->
              <div class="config-menu" v-if="JSON.stringify(flowReturnObj)=='{}' || flowReturnObj.status === 0">
                  <div class="box" :key="key" v-for="(config,key) in configList">
                      <div class="menu-thead" :class="!config.visible?'border':''"  @click="config.visible = !config.visible">
                          {{ config.name }}
                          <Icon :type="config.visible?'ios-remove':'ios-add'" class="arrow"/>
                      </div>
                      <div class="menu-body" :class="!config.visible?'border':''" v-show="config.visible">
                          <ul class="yb-fadeinT">
                              <li class="menu-event" v-show="config.code ==='event'" :key="'event'+index" v-for="(item,index) in config.childNode">
                                  <a href="javascript:" :style="'background-color:'+item.background" @mousedown="startDrag(item, $event,config.code)">
                                      {{ item.name }}
                                  </a>
                              </li>
                              <li v-show="config.code !=='event'" :key="index" v-for="(item,index) in config.childNode">
                                  <a href="javascript:" @mousedown="startDrag(item, $event,config.code)">
                                      <span :class="item.icon" :style="'background-color:'+item.background"></span>
                                      <span class="node-text">{{ item.name }}</span>
                                  </a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <!--e: config Menu-->

              <!--s: config body-->
              <div class="config-content" :style="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1?'width:100%':''">
                  <div id="container" class="container" :style="flowReturnObj.status === 1?'width:100%':''"></div>
                  <div class="config-thumb">
                      <a href="javascript:" v-if="nodeObj.nodeCode !== NODE_NAME.NODE_START && nodeObj.nodeCode !== NODE_NAME.NODE_END && nodeObj.nodeCode" :class="thumbVisible?'node-narrow':'node-enlarge'" @click="thumbVisible = !thumbVisible"></a>
                      <a href="javascript:" class="enlarge" @click="onThumbEnlarge"></a>
                      <a href="javascript:" class="narrow" @click="onThumbNarrow"></a>
<!--                      :style="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE?'background-image:url('+nodeObj.attribute.imageUrl+')':''"-->
                      <div class="thumb" v-if="thumbVisible && nodeObj.nodeCode !== NODE_NAME.NODE_START && JSON.stringify(nodeObj)!=='{}' && nodeObj.attribute.endSettingsType!=='autoCommit' && nodeObj.nodeCode" :class="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE?nodeObj.attribute.imageMode:''">
                          <a href="javascript:" v-if="flowReturnObj.status !== 1 && nodeObj.nodeCode !== NODE_NAME.NODE_END" class="node-clear" @click="onClearNode"><Icon custom="clear"/>删除节点</a>
                          <!--s: 结束-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_END">
                              <h1 class="thumb-end-title">结束</h1>
                              <div class="thumb-end-content">
                                  <h1 class="success-text"><i class="icon"></i>任务已完成</h1>
                                  <h2 class="success-content">仍在上传录像，为保证录像数据完整，请不要立即关机。</h2>
                              </div>
                              <div class="thumb-end-time" v-if="nodeObj.attribute.endSettingsType === 'scheduledSubmission'">{{ nodeObj.attribute.timedJumpNumber }}秒后将自动关闭窗口</div>
                              <div class="thumb-end-btn-list">
                                  <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                              </div>
                          </div>
                          <!--e: 结束-->
                          <!--s: 拍照-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_PHOTO">
                              <h1 class="title">{{ nodeObj.nodeName}}</h1>
                              <div class="text-print-phone" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入拍照</div>
                              <div class="thumb-text">
                                  <div class="thumb-btn-list">
                                      <span v-show="nodeBtnList.buttonCode === defaultButtonName.TRIGGER_PHOTO && !triggerARStatus" :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color+';cursor: pointer;'" @click="triggerARStatus = true">{{ nodeBtnList.buttonText }}</span>
                                      <span v-show="nodeBtnList.buttonCode !== defaultButtonName.TRIGGER_PHOTO && triggerARStatus" :key="'trigger'+key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="nodeBtnList.buttonCode === defaultButtonName.NEXT_STEP?'background:'+nodeBtnList.color : 'background:'+nodeBtnList.color+';cursor: pointer;'" @click="nodeBtnList.buttonCode === defaultButtonName.TAKE_NEW_PHOTO? triggerARStatus = false:null">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 拍照/上一步</div>
                              <i class="photo-l-t"></i>
                              <i class="photo-l-b"></i>
                              <i class="photo-r-t"></i>
                              <i class="photo-r-b"></i>
                          </div>
                          <!--e: 拍照-->
                          <!--s: AI识别-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_AI_IDENTIFY">
                              <h1 class="title">{{ nodeObj.nodeName}}</h1>
                              <div class="thumb-text">
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 上一步</div>
                              <i class="photo-l-t"></i>
                              <i class="photo-l-b"></i>
                              <i class="photo-r-t"></i>
                              <i class="photo-r-b"></i>
                             <div class="ai-identify-box"><i class="ai-identify"></i></div>
                          </div>
                          <!--e: AI识别-->

                          <!--s: 环境监测-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_ENVIRONMENTAL_MONITORING">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-text environmental-bottom">
                                  <div class="environmental-box">
                                      <div class="environmental-box-content">
                                          <Icon custom="mark"></Icon>
                                          <h1 class="title">作业环境数据正常</h1>
                                          <div class="module-box">
                                              <ul>
                                                  <li>
                                                      <span class="title-name">瓦斯</span>
                                                      <span class="box-text">
                                                          <font>-</font>%
                                                      </span>
                                                  </li>
                                                  <li>
                                                      <span class="title-name">一氧化碳</span>
                                                      <span class="box-text">
                                                          <font>-</font>ppm
                                                      </span>
                                                  </li>
                                                  <li>
                                                      <span class="title-name">温度</span>
                                                      <span class="box-text">
                                                          <font>-</font>°C
                                                      </span>
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                            <div class="btn">回到上一级 上一步</div>
                          </div>
                          <!--e: 环境监测-->

                          <!--s: 设备状态-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_DEVICE_STATUS">
                              <h1 class="title">{{ nodeObj.nodeName}}</h1>
                              <div class="thumb-text">
                                  <div class="device-name">对准{{nodeObj.attribute.deviceName}}至识别框内</div>
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 上一步</div>
                              <i class="photo-l-t"></i>
                              <i class="photo-l-b"></i>
                              <i class="photo-r-t"></i>
                              <i class="photo-r-b"></i>
                          </div>
                          <!--e: 设备状态-->

                          <!--s: 语音输入-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_VOICE_INPUT">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-text">
                                  <span class="name"><Icon custom="voice" /><font class="font" v-if="nodeObj.attribute.inputTypeCode === 'number'">{{ nodeObj.attribute.unitType }}</font></span>
                                  <div class="text-print" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入下一步</div>
                                  <div class="thumb-btn-list">
                                      <span v-show="nodeBtnList.buttonCode === defaultButtonName.START_RECORDING && !triggerARStatus" :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color+';cursor: pointer;'" @click="triggerARStatus = true">{{ nodeBtnList.buttonText }}</span>
                                      <span v-show="nodeBtnList.buttonCode !== defaultButtonName.START_RECORDING && triggerARStatus" :key="'recording'+key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="nodeBtnList.buttonCode === defaultButtonName.PLAY_RECORDING || nodeBtnList.buttonCode === defaultButtonName.STOP_PLAY_RECORDING || nodeBtnList.buttonCode === defaultButtonName.NEXT_STEP?'background:'+nodeBtnList.color : 'background:'+nodeBtnList.color+';cursor: pointer;'" @click="nodeBtnList.buttonCode === defaultButtonName.RECORDING_AGAIN? triggerARStatus = false:null">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                          </div>
                          <!--e: 语音输入-->
                          <!--s: 文本-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_TEXT">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-text">
                                  <span class="name" v-html="nodeObj.attribute.nodeContent || '输入文本'"></span>
                                  <div class="text-print" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入下一步</div>
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">上一步<span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList">/{{ nodeBtnList.buttonText }}</span></div>
                          </div>
                          <!--e: 文本-->
                          <!--s: 图片-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-image">
                                  <div class="text-info">
                                      <span class="image" v-if="nodeObj.attribute.imageUrl !==''" :style="'background-image:url('+nodeObj.attribute.imageUrl+')'"></span>
                                      <div class="image-print" v-if="nodeObj.attribute.imageUrl === ''">
                                          <div class="box">
                                              <span><Icon custom="icon-config-image"/></span>
                                              <span>请上传图片</span>
                                          </div>
                                      </div>
                                      <p class="text">{{ nodeObj.attribute.nodeContent || '作业内容' }}</p>
                                  </div>
                                  <div class="text-print" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入下一步</div>
                                  <div class="thumb-btn-list" :style="nodeObj.attribute.timedJumpStatus !== 1?'margin-top:15px':''">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" v-show="(nodeBtnList.buttonText === '查看图片' && nodeBtnList.buttonLookImageStatus ===1) || nodeBtnList.buttonText !== '查看图片'" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
<!--                              <div class="btn">上一步<span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList">/{{ nodeBtnList.buttonText }}</span></div>-->
                          </div>
                          <!--e: 图片-->
                          <!--s: 批量上传-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_OPERATION">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-batch-no-data" v-if="nodeObj.attribute.batchList.length===0"></div>
                              <div class="thumb-batch" v-if="nodeObj.attribute.batchList.length!==0">
                                  <div class="thumb-batch-box">
                                      <div class="li" :key="key" v-for="(item,key) in nodeObj.attribute.batchList">
                                          <span class="number">{{ key+1 }}.</span>
                                          <span class="info">{{ item.entryName }}：{{ item.textInfo }}</span>
                                          <div class="btn-list">
                                              <div class="box">
                                                  <span :key="'btnList'+index" v-for="(btnList,index) in item.btnList">{{ btnList.buttonText }}</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="thumb-batch-btn" v-if="nodeObj.attribute.batchList.length!==0">
                                  <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                              </div>
                              <div class="btn" v-if="nodeObj.attribute.batchList.length!==0">上一步/打开第1、2.../上一页/下一页/第1、2...+按钮名称</div>
                          </div>
                          <!--e: 批量上传-->
                          <!--s: 批量输入-->
                          <div class="thumb-content batch-input-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_INPUT">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-batch-no-data" v-if="nodeObj.attribute.batchInputList.length===0"></div>
                              <div class="thumb-batch" v-if="nodeObj.attribute.batchInputList.length!==0">
                                  <div class="thumb-batch-box">
                                      <div class="li" :key="key" v-for="(item,key) in nodeObj.attribute.batchInputList">
                                          <span class="number">{{ key+1 }}.</span>
                                          <span class="info">{{ item.entryName }}：{{ item.textInfo }}</span>
                                          <div class="btn-list">
                                              <div class="box">
                                                  <span>开始录音</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="thumb-batch-btn" v-if="nodeObj.attribute.batchInputList.length!==0">
                                  <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                              </div>
                              <div class="btn" v-if="nodeObj.attribute.batchInputList.length!==0">上一步/打开第1、2.../上一页/下一页/第1、2...+按钮名称</div>
                          </div>
                          <!--e: 批量输入-->
                          <Spin fix v-if="systemLoading"></Spin>
                      </div>
                  </div>
              </div>
              <!--e: config body-->
          </div>

          <!--s: config system-->
          <div class="config-system">
              <Tabs size="default">
                  <TabPane label="控制设置">
                      <div class="system-content" :class="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1?'spin':''">
                          <div class="system-print" v-if="nodeObj.nodeCode === NODE_NAME.NODE_START && !printVisible">开始节点没有属性</div>
                          <span class="print" v-if="printVisible">未选中模版</span>
                          <span class="print" v-if="startLineVisible">该连接线无需配置触发条件</span>

                          <!--s: 线条配置项-->
                          <div class="system-sub" v-if="!printVisible && lineVisible">
                              <ul>
                                  <li>
                                      <span class="label">触发判断条件<font class="font">*</font></span>
                                      <CheckboxGroup v-model="lineSelectList">
                                          <Checkbox v-show="!btnList.regularButton" :label="btnList.id" :disabled="btnList.lineId!=''&&lineId !== btnList.lineId" :key="key" v-for="(btnList,key) in sourceBtnList" @change.native="onLineCheckBox">{{ btnList.buttonText }}</Checkbox>
                                      </CheckboxGroup>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 线条配置项-->

                          <!--s: 结束-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_END && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">结束设置</span>
                                  </li>
                                  <li>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.endSettingsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in endSettingsType"  @click.native="onEndSettingsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>
                                          {{ nodeObj.attribute.endSettingsType === 'popupConfirmation'? '任务结束时会弹窗提示是否提交':'' }}
                                          {{ nodeObj.attribute.endSettingsType === 'scheduledSubmission'? '任务结束时显示提交窗口，并定时关闭窗口':'' }}
                                          {{ nodeObj.attribute.endSettingsType === 'autoCommit'? '任务结束时会自动提交，不现实提示窗口':'' }}
                                      </div>
                                  </li>
                                  <li>
                                      <div class="time" v-if="nodeObj.attribute.endSettingsType === 'scheduledSubmission'">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData();" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 结束-->
                          <!--s: 拍照-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_PHOTO && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">标题</span>-->
<!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
<!--                                  </li>-->
                                  <li>
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData();" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceNodeFile">
                                              <input type="file" @change="onFileUpLoad($event,'node')" accept="application/pdf,video/*" ref="inputerNodeFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in nodeObj.attribute.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexNodeClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                          <div class="info">
                                              <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时拍照(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.cameraTimerStatus" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发"拍照"按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.cameraTimerStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.cameraTimerNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，拍照完成会定时进入下一步
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 拍照-->
                          <!--s: 语音输入-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_VOICE_INPUT && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">标题</span>-->
<!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">输入类型<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.inputTypeName }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(input,key) in inputType" :disabled="nodeObj.attribute.inputTypeCode === input.typeCode" @click.native="nodeObj.attribute.inputTypeCode !== input.typeCode?onInputTypeSwitch(input):null">{{ input.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.inputTypeCode === 'number'">
                                      <span class="label">单位<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  <input v-model="nodeObj.attribute.unitType" @keyup="onValidateData()" @blur="onUnitTypeCheck" type="text" class="item-input" />
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(unit,key) in unitType" :disabled="nodeObj.attribute.unitType === unit.unitType" @click.native="onUnitTypeSwitch(unit)">{{ unit.unitType }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceNodeFile">
                                              <input type="file" @change="onFileUpLoad($event,'node')" accept="application/pdf,video/*" ref="inputerNodeFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in nodeObj.attribute.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexNodeClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                          <div class="info">
                                              <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时录音(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedRecordingStatus" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发"录音"按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedRecordingStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedRecordingNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                           <Icon custom="info-icon"></Icon>开启后，录音完成会定时进入下一步
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 语音输入-->
                          <!--s: AI识别-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_AI_IDENTIFY && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                <!--                                  <li>-->
                                <!--                                      <span class="label">标题</span>-->
                                <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                <!--                                  </li>-->
                                  <li>
                                      <span class="label">模型名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.attribute.modelName" show-word-limit :maxlength="30" type="textarea" :rows="4" placeholder="请输入" @on-keyup="onModelContent"/>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                              </ul>
                          </div>
                          <!--e: AI识别-->
                          <!--s: 环境监测-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_ENVIRONMENTAL_MONITORING && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                <!--                                  <li>-->
                                <!--                                      <span class="label">标题</span>-->
                                <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                <!--                                  </li>-->
                                  <li>
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 环境监测-->
                          <!--s: 设备状态-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_DEVICE_STATUS && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <span class="label">设备名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.attribute.deviceName" show-word-limit :maxlength="100" type="textarea" :rows="4" placeholder="请输入" @on-keyup="onDeviceContent"/>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">背景(眼镜端)<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.backgroundText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(background,key) in backgroundType" :disabled="nodeObj.attribute.backgroundCode === background.typeCode" @click.native="onBackgroundTypeSwitch(background)">{{ background.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 设备状态-->
                          <!--s: 文本-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_TEXT && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">标题</span>-->
<!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">作业内容<font class="font">*</font></span>
                                      <Input v-model="nodeObj.attribute.nodeContent" show-word-limit maxlength="300" type="textarea" :rows="8" placeholder="请输入作业内容" @on-keyup="onNodeContent"/>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span> <a href="javascript:" class="btn-add" @click="onAddButton"><Icon type="ios-add-circle" />添加按钮</a></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="btn-touch">
                                                  <Poptip placement="bottom">
                                                      <a href="javascript:void(0)" class="set-up">
                                                          <Tooltip content="点击触发" placement="top">
                                                              <Icon type="ios-cog" size="18"/>
                                                          </Tooltip>
                                                      </a>
                                                      <div class="btn-touch-list" slot="content">
                                                          <ul>
                                                              <li :key="'c'+index" v-for="(trigger,index) in triggerList" :style="onTriggerStyle(trigger.value,item.triggerStatus)" @click="onTrigger(trigger,key)">
                                                                  <Icon type="md-checkmark" size="18" v-if="onTriggerCheck(trigger.value,item.triggerStatus)"/>
                                                                  <Icon type="ios-camera" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                                  <Icon type="ios-videocam" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"/>
                                                                  <Icon type="ios-mic" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_AUDIO"/>
                                                                  {{ trigger.label }}
                                                              </li>
                                                          </ul>
                                                      </div>
                                                  </Poptip>
                                              </div>
                                              <div class="btn-default">
                                                  <onSwitch :value="item.defaultStatus" :disabled="item.defaultStatus?true:false" @click.native="onSwitchButton(item,key)" size="small"></onSwitch>
                                                  <span class="default-name">默认</span>
                                              </div>
                                              <a href="javascript:" class="remove" @click="onRemoveBtn(key)" v-if="!item.defaultStatus"><Icon type="ios-trash-outline" /></a>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceNodeFile">
                                              <input type="file" @change="onFileUpLoad($event,'node')" accept="application/pdf,video/*" ref="inputerNodeFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in nodeObj.attribute.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexNodeClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                          <div class="info">
                                              <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">背景(眼镜端)<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                {{ nodeObj.attribute.backgroundText }}
                                                <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                            <DropdownMenu slot="list">
                                                <DropdownItem :key="key" v-for="(background,key) in backgroundType" :disabled="nodeObj.attribute.backgroundCode === background.typeCode" @click.native="onBackgroundTypeSwitch(background)">{{ background.typeName }}</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发默认按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 文本-->
                          <!--s: 图片-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
  <!--                                  <li>-->
  <!--                                      <span class="label">标题</span>-->
  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
  <!--                                  </li>-->
                                  <li>
                                      <span class="label">图片提示<font class="font">*</font></span>
                                      <div class="upload-box">
                                          <div class="upload" id="dropbox">
                                              <div class="box">
                                                  <input type="file" @change="onSignUpLoad($event)" accept="image/*" ref="inputer" class="upload-input">
                                                  <Icon v-if="nodeObj.attribute.imageUrl ===''" type="ios-cloud-upload" size="52" style="color: #515a6e"></Icon>
                                                  <p v-if="nodeObj.attribute.imageUrl ===''" >将文件拖到此处，或<a href="javascript:" @click="choiceImg">点击上传</a></p>
                                                  <div class="upload-image-box" v-if="nodeObj.attribute.imageUrl !==''">
                                                      <img :src="nodeObj.attribute.imageUrl" class="image"/>
                                                      <div class="handle">
                                                          <div class="box">
                                                              <a href="javascript:" @click="onClearImage"><Icon type="ios-trash-outline" :size="25"/></a>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="info"><Icon custom="info-icon"></Icon>只能上传jpg/png/gif文件，且不超过3M</div>
                                      </div>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">图片展示方式</span>-->
<!--                                      <Select v-model="nodeObj.attribute.imageMode" @on-change="onChangeImageMode">-->
<!--                                          <Option v-for="(item,key) in imageReveal" :value="item.attrKey" :key="key">{{ item.name }}</Option>-->
<!--                                      </Select>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">作业内容<font class="font">*</font></span>
                                      <Input maxlength="300" show-word-limit type="textarea" :rows="8" v-model="nodeObj.attribute.nodeContent" placeholder="请输入作业内容" @on-keyup="onNodeContent"/>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span> <a href="javascript:" class="btn-add" @click="onAddButton"><Icon type="ios-add-circle" />添加按钮</a></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item" :style="item.buttonText==='查看图片'?'width:104px':''">
                                                  <span v-if="item.buttonText==='查看图片'">{{ item.buttonText }}</span>
                                                  <Dropdown v-if="item.buttonText!=='查看图片'" @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="btn-look-image-switch" v-if="item.buttonText==='查看图片'">
                                                  <onSwitch :value="item.buttonLookImageStatus" :true-value="1" :false-value="0" @on-change="onSwitchImageButton(key,$event)" size="small"></onSwitch>
                                              </div>
                                              <div class="btn-touch" v-if="item.buttonText!=='查看图片'">
                                                  <Poptip placement="bottom">
                                                      <a href="javascript:void(0)" class="set-up">
                                                          <Tooltip content="点击触发" placement="top">
                                                              <Icon type="ios-cog" size="18"/>
                                                          </Tooltip>
                                                      </a>
                                                      <div class="btn-touch-list" slot="content">
                                                          <ul>
                                                              <li :key="'c'+index" v-for="(trigger,index) in triggerList" :style="onTriggerStyle(trigger.value,item.triggerStatus)" @click="onTrigger(trigger,key)">
                                                                  <Icon type="md-checkmark" size="18" v-if="onTriggerCheck(trigger.value,item.triggerStatus)"/>
                                                                  <Icon type="ios-camera" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                                  <Icon type="ios-videocam" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"/>
                                                                  <Icon type="ios-mic" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_AUDIO"/>
                                                                  {{ trigger.label }}
                                                              </li>
                                                          </ul>
                                                      </div>
                                                  </Poptip>
                                              </div>
                                              <div class="btn-default" v-if="item.buttonText!=='查看图片'">
                                                  <onSwitch :value="item.defaultStatus" :disabled="item.defaultStatus?true:false" @click.native="onSwitchButton(item,key)" size="small"></onSwitch>
                                                  <span class="default-name">默认</span>
                                              </div>
                                              <a href="javascript:" class="remove" @click="onRemoveBtn(key)" v-if="key !== 0 && !item.defaultStatus"><Icon type="ios-trash-outline" /></a>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceNodeFile">
                                              <input type="file" @change="onFileUpLoad($event,'node')" accept="application/pdf,video/*" ref="inputerNodeFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in nodeObj.attribute.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexNodeClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                          <div class="info">
                                              <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <span class="label">背景(眼镜端)<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.backgroundText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                 <DropdownItem :key="key" v-for="(background,key) in backgroundType" :disabled="nodeObj.attribute.backgroundCode === background.typeCode" @click.native="onBackgroundTypeSwitch(background)">{{ background.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发默认按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="onValidateData();$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 图片-->
                          <!--s: 批量操作-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_OPERATION && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">标题</span>-->
<!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">批量操作<font class="font">*</font></span>
                                      <div class="batch-box">

                                          <!--s: 暂无数据提示-->
                                          <div class="batch-spin" v-if="nodeObj.attribute.batchList.length === 0">
                                              <div class="box">
                                                  <Icon custom="batch-icon"></Icon>
                                                  <span class="text">暂无数据</span>
                                              </div>
                                          </div>
                                          <!--e: 暂无数据提示-->

                                          <!--s: 列表数据展示项-->
                                          <div class="batch-list" v-if="nodeObj.attribute.batchList.length !== 0">
                                              <span class="text-info" :key="key" v-for="(batchList,key) in nodeObj.attribute.batchList">{{ batchList.entryName }}</span>
                                          </div>
                                          <!--e: 列表数据展示项-->

                                      </div>
                                      <a href="javascript:" class="batch-btn" @click="onBatchConfig">配置批量操作</a>
                                  </li>
                                  <li v-if="nodeObj.attribute.batchList.length !== 0">
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData()" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceNodeFile">
                                              <input type="file" @change="onFileUpLoad($event,'node')" accept="application/pdf,video/*" ref="inputerNodeFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in nodeObj.attribute.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexNodeClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 批量操作-->
                          <!--s: 批量输入-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_INPUT && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <li>
                                      <span class="label">批量输入<font class="font">*</font></span>
                                      <div class="batch-box">

                                          <!--s: 暂无数据提示-->
                                          <div class="batch-spin" v-if="nodeObj.attribute.batchInputList.length === 0">
                                              <div class="box">
                                                  <Icon custom="batch-icon"></Icon>
                                                  <span class="text">暂无数据</span>
                                              </div>
                                          </div>
                                          <!--e: 暂无数据提示-->

                                          <!--s: 列表数据展示项-->
                                          <div class="batch-list" v-if="nodeObj.attribute.batchInputList.length !== 0">
                                              <span class="text-info" :key="key" v-for="(batchInput,key) in nodeObj.attribute.batchInputList">{{ batchInput.textInfo }}</span>
                                          </div>
                                          <!--e: 列表数据展示项-->

                                      </div>
                                      <a href="javascript:" class="batch-btn" @click="onBatchInputConfig">配置批量输入</a>
                                  </li>
                                  <li v-if="nodeObj.attribute.batchInputList.length !== 0">
                                      <div class="title"><span>按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @keyup="onValidateData();" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceNodeFile">
                                              <input type="file" @change="onFileUpLoad($event,'node')" accept="application/pdf,video/*" ref="inputerNodeFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in nodeObj.attribute.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexNodeClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                          <div class="info">
                                              <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报(眼镜端)</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="onValidateData();$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 批量输入-->
                      </div>
                  </TabPane>
                  <TabPane label="全局设置">
                      <div class="system-content" :class="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1?'spin':''">
                          <div class="system-settings">
                              <ul>
                                  <li>
                                      <span class="label">眼镜全程录像</span>
                                      <div class="switch">
                                          <Poptip
                                              v-if="configParams.videotapeStatus===0"
                                              confirm
                                              placement="bottom-end"
                                              title="开启后，任务执行将会全程录像"
                                              @on-ok="onVideoChange"
                                              >
                                          </Poptip>
                                          <onSwitch v-model="configParams.videotapeStatus" :true-value="1" :false-value="0" @on-change="$forceUpdate();saveStatus = true" size="small"/>
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">眼镜摄像预览窗口</span>
                                      <div class="switch">
                                          <Poptip
                                              v-if="configParams.smallWindowPreview===0"
                                              confirm
                                              placement="bottom-end"
                                              title="开启后将眼镜摄像预览窗口"
                                              @on-ok="onSmallWindowPreview"
                                              >
                                          </Poptip>
                                          <onSwitch v-model="configParams.smallWindowPreview" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">附件（如技术措施等文件）</span>
                                      <div class="upload-cen">
                                          <a href="javascript:" class="upload-btn" @click="choiceFile">
                                              <input type="file" @change="onFileUpLoad($event)" accept="application/pdf,video/*" ref="inputerFile" class="upload-input">
                                              <Icon custom="upload" />点击上传
                                          </a>
                                          <div class="file-box" :key="key" v-for="(list,key) in configParams.annexList">
                                              <Icon v-if="list.annexName.split('.').pop() === 'docx' || list.annexName.split('.').pop() === 'doc'" custom="file img-word"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'pdf'" custom="file img-pdf"/>
                                              <Icon v-if="list.annexName.split('.').pop() === 'mp4'" custom="file img-video"/>
                                              <span class="file-name">{{ list.annexName.replace(/\.[^/.]+$/, "") }}</span>
                                              <a href="javascript:" class="clear" @click="onAnnexClear(key)"><Icon type="md-close" size="16"/></a>
                                          </div>
                                          <div class="info">
                                              <Icon custom="info-icon"></Icon>支持pdf、mp4文件
                                          </div>
                                      </div>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">快捷反馈</span>支持word、pdf、mp4文件-->
<!--                                      <div class="switch">-->
<!--                                          <onSwitch :disabled="true" v-model="configParams.quickFeedback" :true-value="1" :false-value="0" @on-change="onQuickFeedback" size="small"/>-->
<!--                                      </div>-->
<!--                                  </li>-->
<!--                                  <li class="switch-group" v-if="configParams.quickFeedback === 1">-->
<!--                                      <CheckboxGroup v-model="configParams.quickFeedbackList">-->
<!--                                          <Checkbox :label="quickList.typeCode" :disabled="true" :key="key" v-for="(quickList,key) in quickFeedbackArray">{{ quickList.typeName }}</Checkbox>-->
<!--                                      </CheckboxGroup>-->
<!--                                  </li>-->
                              </ul>
                          </div>
                      </div>
                  </TabPane>
              </Tabs>
              <Spin fix v-if="systemLoading"></Spin>
          </div>
          <!--e: config system-->

      </div>
      <!--e: body-->
      <Spin fix v-if="configLoading"></Spin>
      <Modal
          v-model="taskModalVisible"
          title='创建任务'
          footer-hide>
          <div class="device-modal">
              <span class="label">任务名称：</span>
              <Input v-model="configParams.tasksName" placeholder="请输入" style="width: 300px" />
          </div>
          <div class="device-modal">
              <span class="label">任务类型：</span>
              <Select v-model="configParams.tasksType" clearable style="width: 300px">
                  <Option v-for="(type,key) in tasksType" :value="type.value" :key="key">{{ type.label }}</Option>
              </Select>
          </div>
          <div class="device-modal">
              <span class="label">引用流程：</span>
              <Input v-model="flowReturnObj.flowName" disabled placeholder="请输入" style="width: 300px" />
          </div>
          <div class="device-footer">
              <Button type="primary" :loading="editLoading" @click="onSubmitCheck(1)">立即发布</Button>
              <Button type="primary" :loading="editLoading" ghost @click="onSubmitCheck(0)">稍后发布</Button>
              <Button @click="taskModalVisible = false">取 消</Button>
          </div>
      </Modal>

      <!--s: 批量操作-->
      <Drawer
          v-model="batchVisible"
          width="80%"
          :closable="false"
          @on-visible-change="onVisibleChange"
      >
          <div class="layout-batch-body">

              <h1 class="title">批量配置</h1>
              <Table :loading="batchLoading" :columns="batchColumns" :data="nodeObj.attribute?nodeObj.attribute.batchList:[]" @on-row-click="onRowClick">
                  <template slot="序号" slot-scope="{ index }">
                      {{ index+1 }}
                  </template>
                  <template slot="作业内容" slot-scope="{ row,index }">
                      <Input v-model="row.entryName" @click.native.stop @on-keyup="onEntryName($event,index,'OPERATION')" type="textarea" placeholder="请输入" class="batch-content-input"/>
                  </template>
                  <template slot="作业标准" slot-scope="{ row,index }">
                      <Input v-model="row.textInfo" @click.native.stop @on-keyup="onTextInfo($event,index,'OPERATION')" type="textarea" placeholder="请输入" class="batch-content-input"/>
                  </template>
                  <template slot="图片上传" slot-scope="{ row,index }">
                      <div class="batch-image">
                          <div class="box" @click.stop>
                              <a href="javascript:" v-if="row.imageId === ''" @click="choiceBatchImg(index)" class="upload">图片上传</a>
                              <input type="file" @change="onBatchUpLoad($event,index)" :ref="`batchInputer${index}`" class="upload-input">
                              <div class="image-box" v-if="row.imageId !== ''">
                                  <span :style="'background-image:url('+row.imageUrl+')'" class="image"></span>
                                  <div class="handle">
                                      <div class="box">
                                        <a href="javascript:" @click="onLookImage(row)"><Icon type="ios-eye" :size="20"/></a>
                                        <a href="javascript:" @click="onClearBatchImage(index)"><Icon type="ios-trash-outline" :size="20"/></a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </template>
                  <template slot="按钮配置" slot-scope="{ row,index }">
                      <div class="batch-btn-body">
                          <div class="box" @click.stop>
                              <div class="li" :key="'n'+key" v-for="(item,key) in row.btnList">
                                  <div class="color-item">
                                      <Dropdown trigger="click" >
                                          <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                          <DropdownMenu slot="list">
                                              <DropdownItem :key="'c'+c" v-for="(color,c) in colorList" :style="'background:'+color.color" @click.native="onBatchColor(color,index,key)"></DropdownItem>
                                          </DropdownMenu>
                                      </Dropdown>
                                  </div>
                                  <div class="text-item">
                                      <Dropdown @on-click="onBatchText($event,index,key)">
                                          <a href="javascript:void(0)">
                                              <input v-model="item.buttonText" @keyup="onBatchBtnName($event,index,key)" @blur="onButtonBatchCheck(item,index,key)" type="text" class="item-input" />
                                              <Icon type="ios-arrow-down"></Icon>
                                          </a>
                                          <DropdownMenu slot="list">
                                              <DropdownItem :name="btn.btnName" :disabled="onButtonBatchRepeat(btn.btnName,index,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                          </DropdownMenu>
                                      </Dropdown>
                                  </div>
                                  <div class="btn-touch" v-if="!item.defaultStatus">
                                      <Poptip placement="bottom">
                                          <a href="javascript:void(0)" class="set-up">
                                              <Tooltip content="点击触发" placement="top">
                                                  <Icon type="ios-cog" size="18"/>
                                              </Tooltip>
                                          </a>
                                          <div class="btn-touch-list" slot="content">
                                              <ul>
                                                  <li :key="'c'+n" v-for="(trigger,n) in triggerList" :style="onTriggerStyle(trigger.value,item.triggerStatus)" @click="onBatchTrigger(trigger,index,key)">
                                                      <Icon type="md-checkmark" size="18" v-if="onTriggerCheck(trigger.value,item.triggerStatus)"/>
                                                      <Icon type="ios-camera" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                      <Icon type="ios-videocam" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"/>
                                                      <Icon type="ios-mic" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_AUDIO"/>
                                                      {{ trigger.label }}
                                                  </li>
                                              </ul>
                                          </div>
                                      </Poptip>
                                  </div>
                                  <div class="btn-default">
                                    <span class="default-name" v-if="item.defaultStatus">默认</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </template>
                  <template slot="操作" slot-scope="{ index }">
                      <Poptip
                        confirm
                        @click.native.stop
                        title="确认删除这条配置吗?"
                        placement="top-end"
                        @on-ok="onRemoveBatch(index,'OPERATION')">
                        <a href="javascript:">删除</a>
                      </Poptip>
                  </template>
              </Table>
              <a href="javascript:" class="batch-list-btn" @click="onAddBatch"><Icon type="ios-add" size="16"></Icon>新增</a>
          </div>
      </Drawer>
      <!--e: 批量操作-->

      <!--s: 批量输入-->
      <Drawer
          v-model="batchInputVisible"
          width="80%"
          :closable="false"
          @on-visible-change="onVisibleInputChange">

          <div class="layout-batch-body">
              <h1 class="title">配置组合项</h1>
              <Table :columns="batchInputColumns" :data="nodeObj.attribute?nodeObj.attribute.batchInputList:[]" @on-row-click="onRowBatchInputClick">
                  <template slot="序号" slot-scope="{ index }">
                      {{ index+1 }}
                  </template>
                  <template slot="作业内容" slot-scope="{ row,index }">
                      <Input v-model="row.entryName" @click.native.stop @on-keyup="onEntryName($event,index,'INPUT')" type="textarea" placeholder="请输入" class="batch-content-input"/>
                  </template>
                  <template slot="作业标准" slot-scope="{ row,index }">
                      <Input v-model="row.textInfo" @click.native.stop @on-keyup="onTextInfo($event,index,'INPUT')" type="textarea" placeholder="请输入" class="batch-content-input"/>
                  </template>
                  <template slot="语音配置" slot-scope="{ row,index }">
                      <div class="batch-input-voice" @click.stop>
                          <div class="batch-input-list">
                              <span class="name">输入类型</span>
                              <div class="input-li">
                                  <Dropdown trigger="click" >
                                      <a href="javascript:">
                                          {{ row.inputTypeName }}
                                          <Icon type="ios-arrow-down"></Icon>
                                      </a>
                                      <DropdownMenu slot="list">
                                          <DropdownItem :key="key+'input'" v-for="(input,key) in inputType" :disabled="row.inputTypeCode === input.typeCode" @click.native="onBatchInputTypeSwitch(input,index)">{{ input.typeName }}</DropdownItem>
                                      </DropdownMenu>
                                  </Dropdown>
                              </div>
                          </div>
                          <div class="batch-input-list" v-if="row.inputTypeCode === 'number'">
                              <span class="name">单位</span>
                              <div class="input-li">
                                  <Dropdown trigger="click" >
                                      <a href="javascript:">
                                          <input v-model="row.unitType" @keyup="onBatchInputUnitName($event,index)" @blur="onBatchUnitTypeCheck(index,row)" type="text" class="item-input" />
                                          <Icon type="ios-arrow-down"></Icon>
                                      </a>
                                      <DropdownMenu slot="list">
                                          <DropdownItem :key="key+'unit'" v-for="(unit,key) in unitType" :disabled="row.unitType === unit.unitType" @click.native="onBatchUnitTypeSwitch(unit,index)">{{ unit.unitType }}</DropdownItem>
                                      </DropdownMenu>
                                  </Dropdown>
                              </div>
                          </div>
                      </div>
                  </template>
                  <template slot="操作" slot-scope="{ index }">
                      <Poptip
                          confirm
                          @click.native.stop
                          title="确认删除这条配置吗?"
                          placement="top-end"
                          @on-ok="onRemoveBatch(index,'INPUT')">
                          <a href="javascript:">删除</a>
                      </Poptip>
                  </template>
              </Table>
              <a href="javascript:" class="batch-list-btn" @click="onAddBatchInput"><Icon type="ios-add" size="16"></Icon>新增</a>
          </div>

      </Drawer>
      <!--e: 批量输入-->

      <!--s: 查看图片-->
      <div class="device-modal-box" v-if="lookImageVisible">
          <div class="modal-title"><span>{{ imageObj.solution }}</span></div>
          <a class="modal-clear" @click="lookImageVisible = false;imageRatio = 100"><Icon type="md-add" size="30" /></a>
          <div class="modal-image-box" @mousewheel="onMousewheel">
              <div class="image-box-mouse" @mousedown="onMouseDown"
                   @mouseup="onMouseUp"
                   @mouseout="onMouseUp"
                   @mousemove="onMouseMove"
                   :style="'width:'+(imageWidth*(imageRatio/100))+'px;height:'+(imageHeight*(imageRatio/100))+'px;left:'+imageLeft+'px;top:'+imageTop+'px'">
                   <img :src="imageObj.picUrl"/>
              </div>
          </div>
          <div class="modal-control-box">
              <Tooltip content="放大"><a href="javascript:" @click="onImageEnlarge"><i class="enlarge"></i></a></Tooltip>
              <Tooltip content="缩小"><a href="javascript:" @click="onImageNarrow"><i class="narrow"></i><span class="b-before"></span></a></Tooltip>
              <Tooltip content="图片还原"><a href="javascript:" @click="onReduction">{{ imageRatio }}%<span class="b-before"></span></a></Tooltip>
          </div>
      </div>
      <!--e: 查看图片-->

      <!--s: AR端显示-->
      <Modal
          v-model="lookARVisible"
          title="AR端显示"
          width="600"
          footer-hide>
          <div class="batch-thumb">
              <div class="thumb-content">
                  <h1 class="title">批量操作</h1>
                  <div class="thumb-con">
                      <span v-if="lookARObj.imageId!=='' && lookARObj.imageUrl!==''" class="image" :style="'background-image:url('+lookARObj.imageUrl+')'"></span>
                      <div class="info" v-if="lookARObj.imageId!=='' && lookARObj.imageUrl!==''">
                          <span class="info-text">{{ lookARObj.entryName }} : {{ lookARObj.textInfo }}</span>
                          <span class="info-btn">图片全屏</span>
                      </div>
                      <div class="text" v-if="lookARObj.imageId==='' && lookARObj.imageUrl===''">
                          <div class="box">{{ lookARObj.entryName }} : {{ lookARObj.textInfo }}</div>
                      </div>
                  </div>
                  <div class="thumb-batch-btn">
                      <span :style="'background:'+btnList.color+''" :key="key" v-for="(btnList,key) in lookARObj.btnList">{{ btnList.buttonText }}</span>
                  </div>
                  <div class="btn">返回上一级<span v-if="lookARObj.imageId!=='' && lookARObj.imageUrl!==''">/查看图片</span><span :key="key" v-for="(btnList,key) in lookARObj.btnList">/{{ btnList.buttonText }}</span></div>
              </div>
          </div>
      </Modal>
      <!--e: AR端显示-->
      <!--s: AR端批量输入显示-->
      <Modal
          v-model="lookARBatchInputVisible"
          title="AR端显示"
          width="600"
          footer-hide>

          <div class="batch-thumb">
              <div class="thumb-content">

                  <div class="batch-input-top">
                      <span class="back"><Icon type="ios-arrow-back"></Icon></span>
                      <span class="title">{{ lookARObj.entryName }}</span>
                      <div class="time">00:00:01</div>
                  </div>

                  <div class="batch-input-text">{{ lookARObj.textInfo }}</div>

                  <div class="batch-input-number">
                      <Icon custom="voice" />
                      <span class="input"><font v-if="lookARObj.inputTypeCode === 'number'">{{ lookARObj.unitType }}</font></span>
                      <div class="number" v-if="lookARObj.inputTypeCode === 'text'">0 / <font>30</font></div>
                  </div>

                  <div class="batch-input-btn">
                      <span style="background:rgba(45, 140, 240,1)">开始录音</span>
                  </div>

                  <div class="batch-input-btn-list">

                      <span class="btn-left">回到上一级  确定</span>

                      <div class="btn-right"><span class="help"><Icon type="ios-help" /></span>显示帮助</div>

                  </div>
              </div>
          </div>
      </Modal>
      <!--e: AR端批量输入显示-->
  </div>
</template>
<script>
import ProcessConfig from './config'
export default ProcessConfig
</script>
<style lang="less">
@import "config";
</style>
